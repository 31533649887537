.map-container {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: block;
  padding: 35% 0;
  overflow: hidden;
  @include border-radius(5px);

  .google-map {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &.no-controls {
      .gm-style-cc,
      .gmnoprint {
        display: none;
      }
    }
  }
}