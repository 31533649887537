/* Dashboard */
.card-statistics {
  .highlight-icon {
    height: 53px;
    width: 53px;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    @include border-radius(50px);

    i {
      font-size: 27px;
    }
  }
}

.card-revenue-table {
  .revenue-item {
    border-bottom: 1px solid $border-color;
    @extend .py-3;

    &:last-child {
      border-bottom: 0;
      @extend .pb-0;
    }

    &:first-child {
      @extend .pt-0;
    }

    .revenue-desc {
      margin-right: auto;
      width: 80%;

      p {
        margin-bottom: 0;
      }
    }

    .revenue-amount {
      margin-left: auto;
      width: 40%;

      p {
        font-size: 1.25rem;
        font-family: $type-1;
        font-weight: 600;
        text-align: right;

        .rtl & {
          text-align: left;
        }
      }
    }
  }
}

.card-revenue {
  background: $blue-teal-gradient-light;
  background-size: cover;
  color: color(white);

  .highlight-text {
    font-size: 1.875rem;
    font-family: $type-1;
    font-weight: 500;
  }

  .badge {
    background-color: rgba(color(white), .2);
    font-size: 1.125rem;
    padding: 0.5rem 1.25rem;
  }
}

.card-weather {
  background: #e1ecff;
  background-image: linear-gradient(to left bottom, #d6eef6, #dff0fa, #e7f3fc, #eff6fe, #f6f9ff);

  .card-body {
    background: $white;

    &:first-child {
      background: url("../images/samples/weather.svg") no-repeat center;
      background-size: cover;
    }
  }

  .weather-date-location {
    padding: 0 0 38px;
  }

  .weather-data {
    padding: 0 0 4.75rem;

    i {
      font-size: 5.313rem;
      line-height: 1;
    }
  }

  .weakly-weather {
    background: $white;
    overflow-x: auto;

    .weakly-weather-item {
      flex: 0 0 14.28%;
      border-right: 1px solid $border-color;
      padding: 1rem;
      text-align: center;

      i {
        font-size: 1.2rem;
      }

      &:last-child {
        border-right: 0;
      }

      .symbol {
        color: $text-muted;
        font-size: 1.875rem;
        font-weight: 300;
      }
    }
  }
}

.product-chart-wrapper {
  height: 92%;
}

#dashboardTrendingProgress {
  width: 60px;
}

.dashboard-bar-chart-legend {
  .col {
    text-align: center;
    @include display-flex;
    @include align-items(center);
    @include flex-direction(column);

    .bg {
      margin-left: auto;
      margin-right: auto;
      height: 5px;
      width: 30px;
      display: block;
      margin-top: 5px;
    }

    &:nth-child(1) {
      .bg {
        background: theme-color(info);
      }
    }

    &:nth-child(2) {
      .bg {
        background: theme-color(primary);
      }
    }

    &:nth-child(3) {
      .bg {
        background: theme-color(danger);
      }
    }
  }
}
