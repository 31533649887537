/* Pws-tabs */
.pws_tabs_container {
	ul.pws_tabs_controll {
		border-bottom: 1px solid theme-color(primary);

		li {
			&:first-child {
				a {
					@include border-radius(5px 0 0 0);
				}
			}

			&:last-child {
				a {
					border-right: 1px solid theme-color(primary);
					@include border-radius(0 5px 0 0);
				}
			}

			a {
				border: 1px solid theme-color(primary);
				border-bottom: none;
				border-right: none;
				background: #fff;
				color: theme-color(primary);
				padding: 0.3em 1.3em;
				margin-right: 0;
				font-weight: 500;
				font-size: 14px;

				&:hover {
					background: #fff;
					color: theme-color(primary);
				}

				&.pws_tab_active {
					background: theme-color(primary);
					color: #fff;
				}
			}
		}
	}

	.demo-tabs {
		border: 1px solid theme-color(primary);
		@include border-radius(0 5px 5px 5px);
		overflow: hidden;
		background: theme-color(light);
		margin-bottom: 45px;
		padding-top: 3.5rem;

		.CodeMirror {
			margin-bottom: 20px;
		}
	}
	// Styles for vertical Tab
	&.pws_tabs_vertical {
		.pws_tabs_controll {
			border-right: none;
			border-bottom: none;

			li {
				&:first-child {
					a {
						@include border-radius(5px 0 0 0);
					}
				}

				&:last-child {
					a {
						border-bottom: 1px solid theme-color(primary);
						@include border-radius(0 0 0 5px);
						border-right: 0;
					}
				}

				a {
					@include border-radius(0);
					border: 1px solid theme-color(primary);
					border-right: none;
					border-bottom: none;
					margin-bottom: 0;
				}
			}
		}
	}
	// Styles for vertical Tab
	// Styles for horizontal Tab
	&.pws_tabs_horizontal_bottom {
		.pws_tabs_list {
			margin-bottom: 0;
		}

		.pws_tabs_controll {
			border-top: 1px solid theme-color(primary);
			border-right: none;
			border-bottom: none;

			li {
				&:first-child {
					a {
						@include border-radius(0 0 0 5px);
					}
				}

				&:last-child {
					a {
						@include border-radius(0 0 5px 0);
						border-right: 1px solid theme-color(primary);
					}
				}

				a {
					@include border-radius(0);
					border: 1px solid theme-color(primary);
					border-top: none;
					border-right: none;
					margin-right: 0;
					margin-bottom: 0;
				}
			}
		}
	}
	&.pws_tabs_horizontal_top {
		.pws_tabs_controll{
			border-bottom: 0px;
		}
	}
	// Styles for vertical Tab
	// Styles for RTL Tab
	&.pws_tabs_rtl {
		.pws_tabs_controll {
			li {
				&:first-child {
					a {
						@include border-radius(0 5px 0 0);
						border-right: 1px solid theme-color(primary);
					}
				}

				&:last-child {
					a {
						border-right: none;
						@include border-radius(5px 0 0 0);
					}
				}

				a {
					border: 1px solid theme-color(primary);
					border-bottom: none;
					border-right: none;
					margin-right: 0;
					margin-left: 0;
				}
			}
		}
	}
	// Styles for RTL Tab
}
